<template>
  <div class="container">
    <v-card>
      <v-toolbar color="light-blue" class="toolbar">
        <div class="toolbar-title">Controle de Acesso</div>
        <BotaoTutorial class="tutorial-button" urlVideo="" categoria="" legenda="Dúvidas Controle de Acesso"/>
      </v-toolbar>
      <v-card class="filter-container">
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-text-field label="Nome" v-model="nome" class="filter-input"/>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-text-field label="Sobrenome" v-model="sobrenome" class="filter-input"/>
          </v-col>
          <v-col cols="12" sm="12" md="4" lg="4">
            <v-text-field label="UH" v-model="uh" class="filter-input"/>
          </v-col>
        </v-row>
        <v-row class="filter-buttons">
          <v-col class="filter-buttons-col">
            <v-btn @click="limparFiltro()" small color="warning">
              <v-icon color="white">mdi-broom</v-icon> 
              Limpar Filtros
            </v-btn>
          </v-col>
          <v-col class="filter-buttons-col">
            <v-btn @click="listarReservas()" small color="primary">
              <v-icon color="white">mdi-magnify</v-icon>
              Filtrar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="py-2">
        <div v-for="reserva in listaReservas" :key="reserva.idhospede" class="reservation-container">
            <div class="reservation-header">
                <div class="reservation-header-title">Nome</div>
                <div class="reservation-header-value">{{reserva.nome + ' ' + reserva.sobrenome}}</div>
            </div>
            <div class="reservation-detail">
                <div class="reservation-detail-label">Dt Checkin</div>
                <div class="reservation-detail-value">{{reserva.datacheckinprevisto | formatarData}}</div>
            </div>
            <div class="reservation-detail">
                <div class="reservation-detail-label">Dt Checkout</div>
                <div class="reservation-detail-value">{{reserva.datacheckoutprevisto | formatarData}}</div>
            </div>
            <div class="reservation-detail">
                <div class="reservation-detail-label">UH</div>
                <div class="reservation-detail-value">{{reserva.uh}}</div>
            </div>
            <div class="reservation-detail">
                <div class="reservation-detail-label">&Uacute;lt. Dig. Doc.</div>
                <div class="reservation-detail-value">{{reserva.documento ? reserva.documento.slice(reserva.documento.length-3):''}}</div>
            </div>
            <div class="reservation-buttons">
                <div class="reservation-button">
                <v-btn :disabled="!reserva.docfrente&&!reserva.docverso" @click="()=>exibirDocModal(reserva)" small color="warning">
                    {{txtBtnBuscarDoc}}
                </v-btn>
                </div>
                <div class="reservation-button">
                <v-btn :disabled="!reserva.selfie" @click="()=>exibirSelfieModal(reserva)" small color="primary">
                    {{txtBtnBuscarSelfie}}
                </v-btn>
                </div>
            </div>
        </div>
      </v-card>

      <v-dialog v-model="exibirDoc" max-width="500px">
        <div>
            <div class="document-list">
            <img v-if="docfrente" :src="'data:image.png;base64,' + docfrente" class="img-upload" style="max-width:500px;" />
            <img v-if="docverso" :src="'data:image.png;base64,' + docverso" class="img-upload" style="max-width:500px;" />
            <div v-if="carregando" class="loading-message">Aguarde...</div>
            </div>
        </div>
      </v-dialog>
      
      <v-dialog v-model="exibirSelfie" max-width="500px">
        <div class="selfie-card">
            <div class="selfie-dialog">
                <img v-if="selfie" :src="'data:image.png;base64,' + selfie" class="img-upload" style="max-width:500px;" />
                <div v-else class="loading-message">Aguarde...</div>
            </div>
        </div>
      </v-dialog>
      
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor" class="snackbar">
        <div class="snackbar-content">
            <span>{{ snackText }}</span>
            <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
        </div>
      </v-snackbar>
      
    </v-card>
  </div>
</template>


<script>

import BotaoTutorial from '../material/BotaoTutorial.vue'

export default {
    name:"ControleAcesso",
    components: {
    BotaoTutorial
},
    data: () => ({
        nome: '',
        sobrenome: '',
        uh: '',
        textoBtnPequisar: 'Buscar',
        listaReservas: [],
        listadestatus: [],
        exibirDoc: false,
        exibirSelfie: false,
        docfrente: null,
        docverso: null,
        selfie: null,
        assinatura: null,
        txtBtnBuscarDoc: 'Ver Documento',
        txtBtnBuscarSelfie: 'Ver Selfie',
        snack: false,
        snackColor: "",
        snackText: "",
        carregando: false
    }),

    computed: {

    },

    watch: {

    },
    
    created() {
        
    },
    async mounted(){
    },
    methods: {
        limparFiltro() {
            this.nome = ''
            this.sobrenome = ''
            this.uh = ''
        },
        async carregarImagem(reserva,campo) {
            this.$http.post("/imagem/select", {hotel: localStorage.getItem("hotel"), id: reserva[campo]})
            .then(async (responseimg) => {
                if (Array.isArray(responseimg.data) && responseimg.data.length>0) {
                    this[campo] = Buffer.from(responseimg.data[0].dados).toString()
                }
                this.carregando = false;
            }).catch((error) => { 
                console.error(error); 
                this.carregando = false;
            });
        },
        async exibirDocModal(reserva){
            if(reserva.docfrente||reserva.docverso) {
                if (!this.docfrente || !this.docverso) this.carregando = true;
                if(reserva.docfrente){
                    this.carregarImagem(reserva,'docfrente');
                }
                if(reserva.docverso){
                    this.carregarImagem(reserva,'docverso');
                }
                this.exibirDoc = true
            }else{
                alert("Reserva sem imagem de Documento")
            }
        },
        async exibirSelfieModal(reserva){

            if(reserva.selfie){
                if (!this.selfie) this.carregando = true;
                this.exibirSelfie = true
                this.carregarImagem(reserva,'selfie');
            }else{
                this.snack = true;
                this.snackColor = "orange";
                this.snackText = "Reserva sem imagem de Selfie.";
            }
        },
        async listarReservas() {
            //await this.$store.dispatch("mutate_ultimostatus", this.statusreserva)
            //localStorage.setItem("ultimostatus",JSON.stringify(this.statusreserva))
            // get_ultimostatus
            this.textoBtnPequisar = "Buscando..."
            let params = {
                nome:this.nome,
                sobrenome:this.sobrenome,
                uh:this.uh
                //idstatusreserva:this.statusreserva.id
            }
            console.error(JSON.stringify(params,null,2))

            await this.$http.post("/reserva/select/acesso",params)
                .then((response) => {
                    this.listaReservas = response.data;
                    this.textoBtnPequisar = "Buscar"
                })
                .catch((error) => {
                    console.log(error);
                    this.textoBtnPequisar = "Buscar"
                });
        
        }
    }

}
</script>

<style>
  .container {
    padding: 10px;
  }
  .toolbar {
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: space-between;
  }
  .toolbar-title {
    width: 80%;
  }
  .tutorial-button {
    width: 5%;
    margin-right: 30px;
  }
  .filter-container {
    max-width: 100%;
    padding: 10px;
  }
  .filter-input {
    width: 100% !important;
    padding-bottom: 0px;
  }
  .filter-buttons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    text-align: right;
  }
  .filter-buttons-col {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .reservation-container {
    border: 1px solid black;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
  }
  .reservation-header {
    margin-bottom: 10px;
    display: flex;
  }
  .reservation-header-title {
    font-weight: normal;
    width: 40%;
    text-align: right;
  }
  .reservation-header-value {
    font-weight: bold;
    padding-left: 10px;
    font-size: 16px;
    color: rgb(3, 20, 37);
  }
  .reservation-detail {
    display: flex;
  }
  .reservation-detail-label {
    font-weight: normal;
    width: 40%;
    text-align: right;
  }
  .reservation-detail-value {
    font-weight: bold;
    padding-left: 10px;
    font-size: 16px;
  }
  .reservation-buttons {
    display: flex;
    justify-content: flex-end;
  }
  .reservation-button {
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 20px;
  }
  .document-dialog {
    width: 70vw;
    max-width: 70vw;
  }
  .document-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .img-upload {
    max-width: 500px;
    cursor: pointer;
    margin: 5px;
  }
  .loading-message {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selfie-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .selfie-card {
    width: 90vw;
    height: 50vh;
  }
  .snackbar {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  .snackbar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
</style>
